import api from './api';

const BASE_URL = '/contacts';

const knowledgeSources = () => api.get(`${BASE_URL}/knowledge-sources`);

const publicTenders = () => api.get(`${BASE_URL}/public-tenders`);

const sendMessage = (data, reCaptchaToken) =>
  api.post(`${BASE_URL}/messages`, data, {
    headers: {
      'g-recaptcha': reCaptchaToken,
    },
    params: {
      mock: false,
    },
  });

const sendLeadsCapture = (data, reCaptchaToken) =>
  api.post(`${BASE_URL}/lead-captures`, data, {
    headers: {
      'g-recaptcha': reCaptchaToken,
    },
    params: {
      mock: false,
    },
  });

const sendOmbudsmanMessage = (data, reCaptchaToken) =>
  api.post(`${BASE_URL}/ombudsman`, data, {
    headers: {
      'g-recaptcha': reCaptchaToken,
    },
    params: {
      mock: false,
    },
  });

const sendWorkWithUs = (data, reCaptchaToken, onUploadProgress) => {
  return api.post(`${BASE_URL}/work-with-us`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
      'g-recaptcha': reCaptchaToken,
    },
    onUploadProgress,
  });
};

export default {
  knowledgeSources,
  publicTenders,
  sendMessage,
  sendLeadsCapture,
  sendOmbudsmanMessage,
  sendWorkWithUs,
};
