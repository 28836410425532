/* eslint-disable jsx-a11y/label-has-associated-control */
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import toast from 'react-hot-toast';
import InputMask from 'react-input-mask';
import * as Yup from 'yup';
import { Link } from 'gatsby';
import Button from '../../components/ui/button';
import ConsentArea from '../../components/ConsentArea';
import FieldError from '../../components/FieldError';
import SubmitButton from '../../components/SubmitButton/SubmitButton';
import Form, { FormGroup, Input } from '../../components/ui/form';
import Label from '../../components/ui/form/label/label';
import SectionTitle from '../../components/ui/section-title';
import { Col, Row } from '../../components/ui/Wrapper';
import useFeedbackModal from '../../hooks/useFeedbackModal';
import useFormData from '../../hooks/useFormData';
import contactService from '../../services/contactService';
import consentTypes from '../../utils/constants/consentTypes';
import { SectionBottom } from './style';

const schemaValues = {
  name: Yup.string().required('Campo obrigatório'),
  // birthday: Yup.string()
  //  .matches(/^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/, 'Data inválida (dd/mm/aaaa)')
  //  .required('Campo obrigatório'),
  email: Yup.string().email('E-mail inválido').required('Campo obrigatório'),
  phone: Yup.string().required('Campo obrigatório'),
};

const schema = Yup.object().shape(schemaValues);

const consentValidationMessage = 'Você deve consentir com este item para continuar';

const initialValues = {
  name: '',
  // birthday: '',
  email: '',
  phone: '',
  public_tender_ids: [],
  school_unit_id: undefined,
  description: '',
};

const handleCloseFeedback = () => {
  window.location.reload();
};

const LeadsCaptureForm = props => {
  const {
    description,
    publicTenders,
    postTitle,
    postDescription,
    postButtonDescription,
    postButtonAction,
    unitId,
    buttonStyle,
  } = props;
  const { isLoading, showLoading, hideLoading } = useFormData({ skipSegments: true });
  const [validationSchema, setValidationSchema] = useState(schema);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { FeedbackModal, openModal: openFeedbackModal } = useFeedbackModal({
    onClose: handleCloseFeedback,
    hideSocialMedia: true,
  });

  const { handleSubmit, setFieldValue, getFieldProps, values, errors, touched, submitCount, resetForm } = useFormik({
    initialValues,
    validationSchema: useCallback(() => validationSchema, [validationSchema]),
    enableReinitialize: true,
    onSubmit: async data => {
      showLoading();
      try {
        const reCaptchaToken = await executeRecaptcha('contact_form');
        const publicTenderIds = (publicTenders || []).map(({ externalId }) => externalId);
        await contactService.sendLeadsCapture(
          {
            ...data,
            public_tender_ids: publicTenderIds,
            school_unit_id: unitId,
            description,
            // birthday: format(parse(data.birthday, 'dd/MM/yyyy', new Date()), 'yyyy-MM-dd'),
            consents: Object.entries(data.consents).map(([key, value]) => ({ code: key, answer: value })),
          },
          reCaptchaToken
        );

        openFeedbackModal();

        resetForm({});
      } catch (error) {
        console.log(error);
        toast.error(error?.response?.data?.message || 'Aconteceu um erro inesperado. Tente novamente mais tarde');
      } finally {
        hideLoading();
      }
    },
  });

  const handleLoadConsent = (purposes = []) => {
    const newSchema = Yup.object().shape({
      ...schemaValues,
      consents: Yup.object().shape(
        purposes.reduce(
          (acc, { code, required }) => ({
            ...acc,
            [code]: required
              ? Yup.bool().oneOf([true], consentValidationMessage).required(consentValidationMessage)
              : Yup.bool(),
          }),
          {}
        )
      ),
    });

    setValidationSchema(newSchema);
  };

  return (
    <>
      <FeedbackModal title={postTitle}>
        <p style={{ textAlign: 'center' }}>{postDescription}</p>
        <Row>
          <Col lg={12}>
            <SectionBottom className="text-center">
              <Button skin="success" as={Link} to={postButtonAction} target="_blank" {...buttonStyle}>
                {postButtonDescription}
              </Button>
            </SectionBottom>
          </Col>
        </Row>
      </FeedbackModal>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col lg={12}>
            <FormGroup mb="20px">
              <Label name="name" required>
                Nome
              </Label>
              <Input {...getFieldProps('name')} type="text" name="name" id="name" placeholder="Informe o nome" />
              <FieldError name="name" errors={errors} touched={touched} submitCount={submitCount} />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <FormGroup mb="20px">
              <Label name="name" required>
                E-mail
              </Label>
              <Input {...getFieldProps('email')} type="text" name="email" id="email" placeholder="Informe o e-mail" />
              <FieldError name="email" errors={errors} touched={touched} submitCount={submitCount} />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <FormGroup mb="20px">
              <Label name="cell_phone" required>
                Telefone
              </Label>
              <InputMask {...getFieldProps('phone')} mask="(99) 99999-9999" maskChar={null}>
                {inputProps => (
                  <Input {...inputProps} type="text" name="phone" id="phone" placeholder="Informe o telefone" />
                )}
              </InputMask>
              <FieldError name="phone" errors={errors} touched={touched} submitCount={submitCount} />
            </FormGroup>
          </Col>
        </Row>
        <ConsentArea
          type={consentTypes.LEADS_CAPTURE}
          onLoad={handleLoadConsent}
          getFieldProps={getFieldProps}
          errors={errors}
          touched={touched}
          submitCount={submitCount}
        />
        <Row>
          <Col lg={12}>
            <SubmitButton text="Entrar no Grupo" isLoading={isLoading} />
          </Col>
        </Row>
      </Form>
    </>
  );
};

LeadsCaptureForm.propTypes = {
  description: PropTypes.string.isRequired,
  publicTenders: PropTypes.array.isRequired,
  postTitle: PropTypes.string.isRequired,
  postDescription: PropTypes.string.isRequired,
  postButtonDescription: PropTypes.string.isRequired,
  postButtonAction: PropTypes.string.isRequired,
  unitId: PropTypes.number.isRequired,
  buttonStyle: PropTypes.object,
};

LeadsCaptureForm.defaultProps = {
  buttonStyle: {
    m: '10px',
    hover: '2',
  },
};

export default LeadsCaptureForm;
