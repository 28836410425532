import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import FollowSocialMedia from '../ui/FollowSocialMedia/FollowSocialMedia';
import { SocialMediaContainer } from './styles';

const FeedbackModal = ({ title, content, isOpen, hide, hideSocialMedia }) => {
  return (
    <Modal show={isOpen} onHide={hide}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          {content}
          <p>
            Aproveite para conhecer um pouco do <strong>MAIS MILITAR</strong> acessando nossas Redes Sociais.
          </p>
          {!hideSocialMedia && (
            <SocialMediaContainer>
              <FollowSocialMedia hideWhatsapp />
            </SocialMediaContainer>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={hide}>
          Fechar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

FeedbackModal.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.node.isRequired,
  hide: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  hideSocialMedia: PropTypes.bool,
};

FeedbackModal.defaultProps = {
  isOpen: false,
  hideSocialMedia: false,
};

export default FeedbackModal;
