/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import FeedbackModal from '../components/FeedbackModal/FeedbackModal';

export default ({ onClose, hideSocialMedia = false } = {}) => {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    if (onClose) onClose();
  };

  return {
    isOpen,
    openModal,
    closeModal,
    hideSocialMedia,
    FeedbackModal: ({ title, children }) => (
      <FeedbackModal
        title={title}
        content={children}
        isOpen={isOpen}
        hide={closeModal}
        hideSocialMedia={hideSocialMedia}
      />
    ),
  };
};
