import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import parse from 'html-react-parser';
import { MdPhone } from 'react-icons/md';
import { Container, Row, Col } from '../../components/ui/Wrapper';
import Heading from '../../components/ui/Heading';
import Text from '../../components/ui/Text';
import Button from '../../components/ui/button';
import { ContactWrapper, LeftBox, ContactInfoBox } from './about-contact-area.style';

const ContactArea = ({ headingStyle, descStyle, conactInfoStyles: { titleStyle, phoneAnchorStyle, btnStyle } }) => {
  const contactQueryData = useStaticQuery(graphql`
    query ContactQuery {
      sectionData: indexJson(id: { eq: "contact-content" }) {
        title
        desc
      }
      sectionBg: file(relativePath: { eq: "images/bg/camouflaged.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920, maxHeight: 520, quality: 100) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      contactData: site {
        siteMetadata {
          contact {
            phone
          }
          social {
            whatsapp
          }
        }
      }
    }
  `);
  const { title, desc } = contactQueryData.sectionData;
  const imageData = contactQueryData.sectionBg.childImageSharp.fluid;
  const {
    contact: { phone },
    social: { whatsapp },
  } = contactQueryData.contactData.siteMetadata;

  return (
    <ContactWrapper fluid={imageData}>
      <Container>
        <Row alignitems="center">
          <Col lg={6}>
            <LeftBox>
              {title && <Heading {...headingStyle}>{parse(title)}</Heading>}
              {desc && <Text {...descStyle}>{parse(desc)}</Text>}
            </LeftBox>
          </Col>
          <Col lg={6}>
            <ContactInfoBox>
              <MdPhone className="icon" />
              <Heading {...titleStyle}>ENTRE EM CONTATO CONOSCO</Heading>
              <Button to={whatsapp} target="_blank" {...btnStyle}>
                ENVIE-NOS UMA MENSAGEM
              </Button>
            </ContactInfoBox>
          </Col>
        </Row>
      </Container>
    </ContactWrapper>
  );
};

ContactArea.propTypes = {
  sectionStyle: PropTypes.object,
};

ContactArea.defaultProps = {
  headingStyle: {
    as: 'h3',
    position: 'relative',
    pl: '34px',
    fontweight: 600,
    lineHeight: 1.4,
    layout: 'quote',
    child: {
      color: 'primary',
    },
  },
  descStyle: {
    mt: '15px',
    fontSize: '18px',
    ml: '34px',
  },
  conactInfoStyles: {
    titleStyle: {
      as: 'h6',
      fontSize: '15px',
      letterspacing: '2px',
      texttransform: 'uppercase',
      mb: '10px',
      mt: '10px',
    },
    phoneAnchorStyle: {
      color: '#002fa6',
      hover: {
        layout: 2,
        color: '#002fa6',
      },
    },
    btnStyle: {
      mt: '20px',
      skin: 'secondary',
      minwidth: '230px',
      hover: {
        bgColor: 'secondaryDark',
      },
    },
  },
};

export default ContactArea;
