import React from 'react';
import PropTypes from 'prop-types';
import { Error } from '../ui/form';

const FieldError = ({ errors, touched, submitCount, name }) => {
  return errors[name] && (touched[name] || submitCount >= 1) ? <Error>{errors[name]}</Error> : null;
};

FieldError.propTypes = {
  name: PropTypes.string.isRequired,
  errors: PropTypes.object,
  touched: PropTypes.object,
  submitCount: PropTypes.number,
};

FieldError.defaultProps = {
  errors: {},
  touched: {},
  submitCount: 0,
};

export default FieldError;
