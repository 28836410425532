import React, { useContext } from 'react';

import PropTypes from 'prop-types';
import { ThemeContext } from 'styled-components';
import PulseLoader from 'react-spinners/PulseLoader';
import Button from '../ui/button';

const SubmitButton = ({ isLoading, text, ...rest }) => {
  const theme = useContext(ThemeContext);

  return (
    <Button type="submit" {...rest}>
      {isLoading ? <PulseLoader color={theme.colors.white} size={10} /> : <span>{text}</span>}
    </Button>
  );
};

SubmitButton.propTypes = {
  isLoading: PropTypes.bool,
  text: PropTypes.string,
};

SubmitButton.defaultProps = {
  isLoading: false,
  text: 'Enviar',
};

export default SubmitButton;
